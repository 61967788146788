@import url('https://fonts.googleapis.com/css?family=Heebo:300&display=swap');

.bot {
  bottom: 0;
  right: 0;
  margin: 10px;
  position: fixed;
  width: 350px;
  z-index: 94;
}

.btn {
  margin: 5px;
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 40px;
  margin-bottom: 30px;
  float: right;
  background-color: #1e524c;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}
.btn:hover {
  opacity: 0.9;
}
.btn:focus {
  outline: none;
}

a {
  text-decoration: none;
}

.img {
  height: 200px;
  width: 300px;
}
